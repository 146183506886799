body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game-board {
    width: 800px;
    height: 600px;
    border: 3px solid;
    margin: auto;
    padding: 10px;
}

.game-card {
    width: 200px;
    height: 100px;
    border: 1px solid #000;
    display: inline-block;
    margin: 0 20px 20px 0;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.game-card svg {
  display: inline-block;
  padding-right: 8px;
  width: 50px;
  height: 100px;
  stroke-width: 8px;
  stroke-opacity: 100%;
  overflow: visible;
}

.game-card.color-red svg {
    fill: red;
    stroke: red;
}

.game-card.color-green svg {
    fill: green;
    stroke: green;
}

.game-card.color-purple svg {
    fill: purple;
    stroke: purple;
}

.game-card.fill-empty svg {
    fill-opacity: 0%;
}

.game-card.fill-shaded svg .shape-main {
   fill: url(#diagonalHatch);
}

.game-card.fill-solid svg {
    fill-opacity: 100%;
}
